import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import breakpoints from "../../components/breakpoints"
import { Arrow } from "../../svg/miscellaneous"

export const PrevButtonLatestProjects = ({ enabled, onClick }) => {
  return (
    <EmblaButtonLatestProjects
      onClick={onClick}
      disabled={!enabled}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <svg
        width="53"
        height="32"
        viewBox="0 0 53 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.00755 17.0708L1.00746 17.0716L1.01977 17.0716C11.8962 17.0716 19.899 19.5057 24.1802 24.0773L24.1802 24.0773C25.7827 25.7876 26.5977 27.5132 27.0123 28.8141C27.2196 29.4647 27.3268 30.0091 27.3821 30.3921C27.4098 30.5836 27.4244 30.7347 27.4322 30.8386C27.4361 30.8905 27.4382 30.9306 27.4394 30.958L27.4406 30.9894C27.4407 30.9956 27.4408 30.999 27.4408 30.9998L27.4408 31L27.4408 31.1L27.5408 31.1L29.8003 31.1L29.9003 31.1L29.9003 31C29.9003 30.7778 29.8483 25.8371 24.7818 21.4617C22.6435 19.6044 19.9346 18.1398 16.7143 17.074L52 17.074L52.1 17.074L52.1 16.974L52.1 15.026L52.1 14.926L52 14.926L16.688 14.926C19.9067 13.8591 22.5978 12.3971 24.759 10.5385L24.7592 10.5383C29.8342 6.15308 29.8777 1.22463 29.8777 0.999998L29.8777 0.899998L29.7777 0.899998L27.5182 0.899997L27.4182 0.899997L27.4182 0.999998L27.4182 1.00095L27.4182 1.00375L27.418 1.01401C27.4178 1.02287 27.4174 1.03568 27.4167 1.05232C27.4154 1.0856 27.4129 1.13412 27.4081 1.19675C27.3985 1.32201 27.38 1.50367 27.3442 1.73266C27.2724 2.19066 27.1314 2.83776 26.854 3.60152C26.2995 5.12857 25.2 7.1231 23.0186 9.00628L23.0185 9.0063C18.4841 12.923 11.0825 14.9211 1 14.9211L0.900003 14.9211L0.900003 15.0211L0.900003 16.9692L0.900003 17.0576L0.987779 17.0684L1.00755 17.0708Z"
          fill="#F7F7FC"
          stroke="#F7F7FC"
          strokeWidth="0.2"
        />
      </svg>
    </EmblaButtonLatestProjects>
  )
}

export const NextButtonLatestProjects = ({ enabled, onClick }) => {
  return (
    <EmblaButtonLatestProjects
      onClick={onClick}
      disabled={!enabled}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <svg
        width="53"
        height="32"
        viewBox="0 0 53 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.9925 14.9292L51.9925 14.9284H51.9802C41.1038 14.9284 33.101 12.4942 28.8198 7.92272L28.8198 7.9227C27.2173 6.21237 26.4023 4.48676 25.9877 3.18585C25.7804 2.5353 25.6732 1.99095 25.6179 1.60791C25.5902 1.4164 25.5756 1.26526 25.5678 1.1614C25.5639 1.10948 25.5618 1.06938 25.5606 1.04198L25.5594 1.01056C25.5593 1.00439 25.5592 1.00104 25.5592 1.00019L25.5592 1V0.9H25.4592H23.1997H23.0997V1C23.0997 1.22223 23.1517 6.16285 28.2182 10.5383C30.3565 12.3956 33.0654 13.8602 36.2857 14.926H1H0.9V15.026V16.974V17.074H1H36.312C33.0933 18.1409 30.4022 19.6029 28.241 21.4615L28.2408 21.4617C23.1658 25.8469 23.1223 30.7754 23.1223 31V31.1H23.2223H25.4818H25.5818V31L25.5818 30.9991L25.5818 30.9963L25.582 30.986C25.5822 30.9771 25.5826 30.9643 25.5833 30.9477C25.5846 30.9144 25.5871 30.8659 25.5919 30.8033C25.6015 30.678 25.62 30.4963 25.6558 30.2673C25.7276 29.8093 25.8687 29.1622 26.146 28.3985C26.7005 26.8714 27.8 24.8769 29.9814 22.9937L29.9815 22.9937C34.5159 19.077 41.9175 17.0789 52 17.0789H52.1V16.9789V15.0308V14.9424L52.0122 14.9316L51.9925 14.9292Z"
          fill="#F7F7FC"
          stroke="#F7F7FC"
          strokeWidth="0.2"
        />
      </svg>
    </EmblaButtonLatestProjects>
  )
}


const EmblaButtonLatestProjects = styled(motion.button)`
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  border: 0;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  /* fill: #1bcacd; */
  padding: 0;

  svg {
    margin-top: .2rem;
    max-width: 30px;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  @media (max-width: ${breakpoints.s}px) {
    /* top: 91%; */

    svg {
      margin-top: .15rem;
      max-width: 25px;
      opacity: 0.9;
      filter: brightness(6);
    }
  }
`

export const PrevButton = ({ enabled, onClick }) => {
  return (
    <EmblaButton
      onClick={onClick}
      disabled={!enabled}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <svg
        width="53"
        height="32"
        viewBox="0 0 53 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.00755 17.0708L1.00746 17.0716L1.01977 17.0716C11.8962 17.0716 19.899 19.5057 24.1802 24.0773L24.1802 24.0773C25.7827 25.7876 26.5977 27.5132 27.0123 28.8141C27.2196 29.4647 27.3268 30.0091 27.3821 30.3921C27.4098 30.5836 27.4244 30.7347 27.4322 30.8386C27.4361 30.8905 27.4382 30.9306 27.4394 30.958L27.4406 30.9894C27.4407 30.9956 27.4408 30.999 27.4408 30.9998L27.4408 31L27.4408 31.1L27.5408 31.1L29.8003 31.1L29.9003 31.1L29.9003 31C29.9003 30.7778 29.8483 25.8371 24.7818 21.4617C22.6435 19.6044 19.9346 18.1398 16.7143 17.074L52 17.074L52.1 17.074L52.1 16.974L52.1 15.026L52.1 14.926L52 14.926L16.688 14.926C19.9067 13.8591 22.5978 12.3971 24.759 10.5385L24.7592 10.5383C29.8342 6.15308 29.8777 1.22463 29.8777 0.999998L29.8777 0.899998L29.7777 0.899998L27.5182 0.899997L27.4182 0.899997L27.4182 0.999998L27.4182 1.00095L27.4182 1.00375L27.418 1.01401C27.4178 1.02287 27.4174 1.03568 27.4167 1.05232C27.4154 1.0856 27.4129 1.13412 27.4081 1.19675C27.3985 1.32201 27.38 1.50367 27.3442 1.73266C27.2724 2.19066 27.1314 2.83776 26.854 3.60152C26.2995 5.12857 25.2 7.1231 23.0186 9.00628L23.0185 9.0063C18.4841 12.923 11.0825 14.9211 1 14.9211L0.900003 14.9211L0.900003 15.0211L0.900003 16.9692L0.900003 17.0576L0.987779 17.0684L1.00755 17.0708Z"
          fill="#6653A3"
          stroke="#6653A3"
          strokeWidth="0.2"
        />
      </svg>
    </EmblaButton>
  )
}

export const NextButton = ({ enabled, onClick }) => {
  return (
    <EmblaButton
      onClick={onClick}
      disabled={!enabled}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <svg
        width="53"
        height="32"
        viewBox="0 0 53 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.9925 14.9292L51.9925 14.9284H51.9802C41.1038 14.9284 33.101 12.4942 28.8198 7.92272L28.8198 7.9227C27.2173 6.21237 26.4023 4.48676 25.9877 3.18585C25.7804 2.5353 25.6732 1.99095 25.6179 1.60791C25.5902 1.4164 25.5756 1.26526 25.5678 1.1614C25.5639 1.10948 25.5618 1.06938 25.5606 1.04198L25.5594 1.01056C25.5593 1.00439 25.5592 1.00104 25.5592 1.00019L25.5592 1V0.9H25.4592H23.1997H23.0997V1C23.0997 1.22223 23.1517 6.16285 28.2182 10.5383C30.3565 12.3956 33.0654 13.8602 36.2857 14.926H1H0.9V15.026V16.974V17.074H1H36.312C33.0933 18.1409 30.4022 19.6029 28.241 21.4615L28.2408 21.4617C23.1658 25.8469 23.1223 30.7754 23.1223 31V31.1H23.2223H25.4818H25.5818V31L25.5818 30.9991L25.5818 30.9963L25.582 30.986C25.5822 30.9771 25.5826 30.9643 25.5833 30.9477C25.5846 30.9144 25.5871 30.8659 25.5919 30.8033C25.6015 30.678 25.62 30.4963 25.6558 30.2673C25.7276 29.8093 25.8687 29.1622 26.146 28.3985C26.7005 26.8714 27.8 24.8769 29.9814 22.9937L29.9815 22.9937C34.5159 19.077 41.9175 17.0789 52 17.0789H52.1V16.9789V15.0308V14.9424L52.0122 14.9316L51.9925 14.9292Z"
          fill="#6653A3"
          stroke="#6653A3"
          strokeWidth="0.2"
        />
      </svg>
    </EmblaButton>
  )
}
export const PrevButtonPress = ({ enabled, onClick }) => {
  return (
    <EmblaButtonPress
      onClick={onClick}
      disabled={!enabled}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <svg
        width="53"
        height="32"
        viewBox="0 0 53 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.00755 17.0708L1.00746 17.0716L1.01977 17.0716C11.8962 17.0716 19.899 19.5057 24.1802 24.0773L24.1802 24.0773C25.7827 25.7876 26.5977 27.5132 27.0123 28.8141C27.2196 29.4647 27.3268 30.0091 27.3821 30.3921C27.4098 30.5836 27.4244 30.7347 27.4322 30.8386C27.4361 30.8905 27.4382 30.9306 27.4394 30.958L27.4406 30.9894C27.4407 30.9956 27.4408 30.999 27.4408 30.9998L27.4408 31L27.4408 31.1L27.5408 31.1L29.8003 31.1L29.9003 31.1L29.9003 31C29.9003 30.7778 29.8483 25.8371 24.7818 21.4617C22.6435 19.6044 19.9346 18.1398 16.7143 17.074L52 17.074L52.1 17.074L52.1 16.974L52.1 15.026L52.1 14.926L52 14.926L16.688 14.926C19.9067 13.8591 22.5978 12.3971 24.759 10.5385L24.7592 10.5383C29.8342 6.15308 29.8777 1.22463 29.8777 0.999998L29.8777 0.899998L29.7777 0.899998L27.5182 0.899997L27.4182 0.899997L27.4182 0.999998L27.4182 1.00095L27.4182 1.00375L27.418 1.01401C27.4178 1.02287 27.4174 1.03568 27.4167 1.05232C27.4154 1.0856 27.4129 1.13412 27.4081 1.19675C27.3985 1.32201 27.38 1.50367 27.3442 1.73266C27.2724 2.19066 27.1314 2.83776 26.854 3.60152C26.2995 5.12857 25.2 7.1231 23.0186 9.00628L23.0185 9.0063C18.4841 12.923 11.0825 14.9211 1 14.9211L0.900003 14.9211L0.900003 15.0211L0.900003 16.9692L0.900003 17.0576L0.987779 17.0684L1.00755 17.0708Z"
          fill="#6653A3"
          stroke="#6653A3"
          strokeWidth="0.2"
        />
      </svg>
    </EmblaButtonPress>
  )
}

export const NextButtonPress = ({ enabled, onClick }) => {
  return (
    <EmblaButtonPress
      onClick={onClick}
      disabled={!enabled}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <svg
        width="53"
        height="32"
        viewBox="0 0 53 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.9925 14.9292L51.9925 14.9284H51.9802C41.1038 14.9284 33.101 12.4942 28.8198 7.92272L28.8198 7.9227C27.2173 6.21237 26.4023 4.48676 25.9877 3.18585C25.7804 2.5353 25.6732 1.99095 25.6179 1.60791C25.5902 1.4164 25.5756 1.26526 25.5678 1.1614C25.5639 1.10948 25.5618 1.06938 25.5606 1.04198L25.5594 1.01056C25.5593 1.00439 25.5592 1.00104 25.5592 1.00019L25.5592 1V0.9H25.4592H23.1997H23.0997V1C23.0997 1.22223 23.1517 6.16285 28.2182 10.5383C30.3565 12.3956 33.0654 13.8602 36.2857 14.926H1H0.9V15.026V16.974V17.074H1H36.312C33.0933 18.1409 30.4022 19.6029 28.241 21.4615L28.2408 21.4617C23.1658 25.8469 23.1223 30.7754 23.1223 31V31.1H23.2223H25.4818H25.5818V31L25.5818 30.9991L25.5818 30.9963L25.582 30.986C25.5822 30.9771 25.5826 30.9643 25.5833 30.9477C25.5846 30.9144 25.5871 30.8659 25.5919 30.8033C25.6015 30.678 25.62 30.4963 25.6558 30.2673C25.7276 29.8093 25.8687 29.1622 26.146 28.3985C26.7005 26.8714 27.8 24.8769 29.9814 22.9937L29.9815 22.9937C34.5159 19.077 41.9175 17.0789 52 17.0789H52.1V16.9789V15.0308V14.9424L52.0122 14.9316L51.9925 14.9292Z"
          fill="#6653A3"
          stroke="#6653A3"
          strokeWidth="0.2"
        />
      </svg>
    </EmblaButtonPress>
  )
}

export const MoreEventsButton = ({ enabled, onClick, text }) => {
  return (
    <EventsButton
      onClick={onClick}
      disabled={!enabled}
      whileTap={{ scale: 0.9 }}
    >
      {text} <Arrow />
    </EventsButton>
  )
}

export const PressPlaySVG = () => {
  return (
    <svg
      width="215"
      height="215"
      viewBox="0 0 215 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.18789 72.8671L19.0049 73.937L6.49223 66.0751L7.50572 63.0818L24.4292 73.9781L23.4572 76.8515L9.03721 75.9109L21.0864 83.8516L20.1239 86.6944L0 85.2542L1.03364 82.1974L15.795 83.4166L3.34865 75.3748L4.18789 72.8671Z"
        fill="#F7F7FC"
      />
      <path
        d="M14.8324 50.9827L35.4222 54.7132L33.6667 57.569L28.0042 56.4732L24.1103 62.8055L27.6853 67.2966L25.9452 70.1253L13.1753 53.6774L14.8324 50.9827ZM22.2125 60.4376L24.9957 55.9112L17.4402 54.444L22.2125 60.4376Z"
        fill="#F7F7FC"
      />
      <path
        d="M20.1511 42.8716L30.9533 30.7772L33.1463 32.703L28.8398 37.5234L41.2731 48.4573L39.079 50.9145L26.6445 39.9864L22.3381 44.8068L20.1511 42.8716Z"
        fill="#F7F7FC"
      />
      <path
        d="M40.3771 22.6356C41.7943 21.3783 43.6386 20.6994 45.5396 20.7352C47.4406 20.7709 49.2576 21.5187 50.6257 22.8284L48.0665 24.8106C47.2205 24.1692 46.1781 23.8354 45.1136 23.8652C44.0492 23.8949 43.0274 24.2864 42.2191 24.974C39.5094 27.0727 39.6955 30.6938 42.2191 33.9093C44.7274 37.0943 48.2182 38.1947 50.9529 36.0773C51.825 35.4632 52.4564 34.569 52.7403 33.5462C53.0241 32.5234 52.9429 31.4348 52.5105 30.4645L55.0697 28.4835C56.0239 30.1186 56.3197 32.051 55.8976 33.8933C55.4755 35.7356 54.3668 37.3515 52.7938 38.4169C48.8299 41.4866 43.2871 40.6507 39.5852 35.948C35.8834 31.2452 36.4132 25.7053 40.3771 22.6356Z"
        fill="#F7F7FC"
      />
      <path
        d="M66.9481 7.61615L75.5112 25.0411L72.5347 26.4801L68.7842 18.8452L59.9426 23.12L63.6931 30.7538L60.7178 32.1881L52.1548 14.762L55.1312 13.323L58.6589 20.5041L67.5017 16.2304L63.9729 9.04932L66.9481 7.61615Z"
        fill="#F7F7FC"
      />
      <path
        d="M88.6049 21.5998L85.3807 22.3417L80.9604 3.4624L84.1846 2.71936L88.6049 21.5998Z"
        fill="#F7F7FC"
      />
      <path
        d="M107.765 19.3178L105.227 19.5259L94.093 6.27702L95.2654 20.343L92.0933 20.6028L90.4824 1.28268L93.5916 1.02756L104.1 13.6627L102.982 0.259829L106.154 0L107.765 19.3178Z"
        fill="#F7F7FC"
      />
      <path
        d="M133.212 3.31311L133.606 18.0351L140.253 4.91205L143.357 5.61747L134.101 23.3963L131.124 22.7214L130.638 8.39915L123.865 21.0766L120.918 20.4077L120.371 0.406799L123.535 1.12397L123.772 15.8107L130.606 2.72644L133.212 3.31311Z"
        fill="#F7F7FC"
      />
      <path
        d="M147.152 29.1913C142.42 26.8587 140.988 21.4317 143.575 16.2587C146.183 11.0563 151.398 8.94002 156.131 11.2714C160.865 13.6028 162.344 19.0204 159.737 24.2228C157.146 29.3912 151.884 31.5215 147.152 29.1913ZM154.815 13.8944C151.733 12.3777 148.414 14.0519 146.568 17.7283C144.722 21.4047 145.391 25.0446 148.465 26.5613C151.538 28.0779 154.899 26.4179 156.74 22.7426C158.58 19.0674 157.893 15.4122 154.815 13.8944Z"
        fill="#F7F7FC"
      />
      <path
        d="M169.505 43.925L167.516 42.3508L168.574 25.1457L159.705 36.1714L157.216 34.2032L169.404 19.058L171.841 20.9873L170.751 37.32L179.202 26.8117L181.691 28.7798L169.505 43.925Z"
        fill="#F7F7FC"
      />
      <path
        d="M186.893 33.8999L191.09 38.9354C194.765 43.3384 194.188 48.5972 189.602 52.3583C185.016 56.1193 179.7 55.6866 176.031 51.2837L171.834 46.2482L186.893 33.8999ZM178.23 49.3156C180.846 52.4535 184.275 52.4629 187.494 49.8282C190.731 47.1735 191.367 43.8321 188.752 40.6883L186.744 38.2805L176.223 46.9078L178.23 49.3156Z"
        fill="#F7F7FC"
      />
      <path
        d="M206.458 62.2988L203.898 63.6967L199.306 55.4304L194.791 57.8993L198.953 65.392L196.416 66.777L192.254 59.2855L187.349 61.9661L191.941 70.2323L189.349 71.6432L183.185 60.5587L200.298 51.2061L206.458 62.2988Z"
        fill="#F7F7FC"
      />
      <path
        d="M198.989 74.7199L191.478 77.2171L190.426 74.105L208.95 67.9479L211.347 75.0397C212.63 78.8395 211.528 81.9351 207.909 83.1391C204.802 84.1713 202.415 82.9674 200.982 80.2151L195.149 88.0793L193.955 84.5522L199.738 76.9373L198.989 74.7199ZM201.615 73.8476L202.939 77.7673C203.636 79.8318 205.147 80.6289 206.866 80.0564C208.585 79.4838 209.308 77.9472 208.611 75.8815L207.286 71.9618L201.615 73.8476Z"
        fill="#F7F7FC"
      />
      <path
        d="M199.549 90.433C199.64 90.8557 199.602 91.2957 199.44 91.6971C199.279 92.0984 199 92.443 198.641 92.687C198.281 92.9309 197.856 93.0633 197.42 93.0671C196.984 93.0709 196.557 92.9461 196.193 92.7085C195.829 92.4709 195.545 92.1312 195.376 91.7327C195.207 91.3343 195.162 90.895 195.245 90.4708C195.329 90.0466 195.538 89.6566 195.845 89.3505C196.153 89.0444 196.546 88.836 196.973 88.7518C197.254 88.6908 197.544 88.6861 197.826 88.738C198.109 88.7899 198.378 88.8973 198.618 89.054C198.858 89.2106 199.064 89.4134 199.224 89.6502C199.384 89.887 199.494 90.1532 199.549 90.433V90.433ZM214.256 85.6033L215 89.3185L201.701 91.1349L201.268 88.9669L214.256 85.6033Z"
        fill="#F7F7FC"
      />
      <path
        d="M21.6341 159.581L15.5081 163.493L13.717 160.734L30.1414 150.246L34.2226 156.535C36.4096 159.905 36.073 163.237 32.6757 165.403C29.3318 167.538 26.1112 166.48 23.9242 163.111L21.6341 159.581ZM31.7808 158.019L29.5286 154.544L24.0451 158.045L26.2973 161.52C26.5152 161.906 26.8105 162.243 27.1649 162.512C27.5192 162.78 27.9253 162.974 28.3579 163.08C28.7905 163.187 29.2406 163.205 29.6805 163.133C30.1204 163.061 30.5407 162.9 30.9156 162.661C31.2905 162.421 31.612 162.108 31.8603 161.741C32.1087 161.374 32.2786 160.96 32.3597 160.525C32.4407 160.09 32.4312 159.644 32.3316 159.213C32.2321 158.782 32.0446 158.375 31.7808 158.019V158.019Z"
        fill="#F7F7FC"
      />
      <path
        d="M32.984 173.897L27.2279 179.297L24.9543 176.916L39.1479 163.598L44.3303 169.032C47.1064 171.944 47.4205 175.211 44.6468 177.814C42.2654 180.048 39.59 179.953 37.1221 178.049L35.1662 187.617L32.5857 184.913L34.6008 175.592L32.984 173.897ZM34.9991 172.01L37.8641 175.014C39.3731 176.595 41.0812 176.687 42.3981 175.45C43.7151 174.213 43.7186 172.518 42.2097 170.936L39.3446 167.932L34.9991 172.01Z"
        fill="#F7F7FC"
      />
      <path
        d="M61.0616 182.785L59.3286 185.124L51.6605 179.541L48.6093 183.662L55.5603 188.717L53.8474 191.032L46.8965 185.977L43.5834 190.454L51.2515 196.038L49.5007 198.404L39.2153 190.915L50.7762 175.296L61.0616 182.785Z"
        fill="#F7F7FC"
      />
      <path
        d="M58.691 197.428C58.546 198.375 58.7321 199.342 59.2185 200.169C59.7048 200.996 60.462 201.633 61.364 201.973C63.4194 202.977 65.2389 202.672 66.0414 201.057C66.646 199.841 66.2844 198.784 64.6688 197.466L62.0527 195.307C59.9084 193.554 58.6151 191.303 60.0079 188.501C61.4707 185.556 64.9948 184.534 68.5343 186.267C72.3274 188.124 73.5993 191.137 72.7921 194.332L69.8287 192.88C69.9368 192.017 69.7491 191.142 69.2957 190.397C68.8423 189.652 68.1496 189.08 67.3287 188.773C65.4452 187.851 63.6826 188.185 62.9654 189.63C62.3881 190.79 62.7639 191.889 64.1508 193.025L66.8534 195.226C69.2834 197.188 70.291 199.652 68.9989 202.258C67.3809 205.513 63.7561 206.239 60.1312 204.467C56.421 202.652 54.6951 199.486 55.6707 195.951L58.691 197.428Z"
        fill="#F7F7FC"
      />
      <path
        d="M75.0881 204.52C75.1141 205.476 75.4692 206.395 76.0946 207.124C76.72 207.852 77.5778 208.346 78.5257 208.524C80.7269 209.153 82.4646 208.533 82.966 206.803C83.3453 205.499 82.8013 204.522 80.9758 203.511L78.0124 201.844C75.5883 200.494 73.9146 198.505 74.7858 195.495C75.7021 192.341 78.9891 190.717 82.7811 191.801C86.8493 192.963 88.6356 195.707 88.4104 198.993L85.2301 198.084C85.1829 197.215 84.8424 196.387 84.2634 195.733C83.6845 195.079 82.9006 194.637 82.0379 194.478C80.0228 193.902 78.3419 194.541 77.8891 196.088C77.5335 197.332 78.0954 198.347 79.6672 199.222L82.7195 200.915C85.4624 202.421 86.8955 204.67 86.0859 207.461C85.0724 210.95 81.6337 212.301 77.7481 211.191C73.7724 210.055 71.5095 207.24 71.8414 203.59L75.0881 204.52Z"
        fill="#F7F7FC"
      />
      <path
        d="M97.379 195.42L113.668 195.76L113.607 198.663L107.116 198.527L106.767 215L103.459 214.931L103.807 198.458L97.3174 198.323L97.379 195.42Z"
        fill="#F7F7FC"
      />
      <path
        d="M125.75 213.755C120.544 214.645 116.149 211.105 115.16 205.408C114.165 199.684 117.115 194.919 122.321 194.029C127.527 193.139 131.947 196.643 132.943 202.367C133.932 208.063 130.956 212.865 125.75 213.755ZM122.819 196.926C119.432 197.505 117.746 200.806 118.448 204.848C119.151 208.89 121.855 211.441 125.242 210.862C128.628 210.282 130.347 206.982 129.645 202.934C128.944 198.886 126.211 196.346 122.819 196.926Z"
        fill="#F7F7FC"
      />
      <path
        d="M147.712 199.419L150.867 205.934L147.884 207.357L139.421 189.886L146.221 186.647C149.863 184.91 153.154 185.654 154.905 189.268C156.627 192.824 155.17 195.864 151.526 197.601L147.712 199.419ZM147.404 189.235L143.645 191.024L146.471 196.857L150.228 195.066C150.641 194.899 151.016 194.65 151.328 194.335C151.641 194.019 151.885 193.643 152.045 193.231C152.206 192.818 152.28 192.377 152.262 191.935C152.244 191.493 152.135 191.059 151.942 190.66C151.749 190.262 151.476 189.906 151.139 189.616C150.802 189.326 150.409 189.108 149.984 188.974C149.559 188.84 149.11 188.794 148.667 188.839C148.223 188.884 147.793 189.018 147.404 189.234V189.235Z"
        fill="#F7F7FC"
      />
      <path
        d="M153.931 182.587L156.613 180.664L166.333 193.992L173.942 188.535L175.676 190.915L165.384 198.295L153.931 182.587Z"
        fill="#F7F7FC"
      />
      <path
        d="M172.23 167.187L191.596 175.064L189.282 177.498L183.972 175.264L178.842 180.66L181.402 185.788L179.11 188.199L170.046 169.484L172.23 167.187ZM177.479 177.952L181.146 174.094L174.056 171.108L177.479 177.952Z"
        fill="#F7F7FC"
      />
      <path
        d="M183.721 151.743L185.633 148.834L191.595 161.093L198.127 165.316L196.337 168.041L189.804 163.816L176.108 163.325L178.073 160.337L188.237 160.768L183.721 151.743Z"
        fill="#F7F7FC"
      />
    </svg>
  )
}
export const PressPauseSVG = () => {
  return (
    <svg
      width="215"
      height="215"
      viewBox="0 0 215 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M194.229 146.719L187.908 143.61L189.34 140.773L206.289 149.111L202.985 155.557C201.234 159.025 198.271 160.383 194.769 158.666C191.267 156.948 190.606 153.796 192.357 150.339L194.229 146.719ZM200.573 154.329L202.368 150.763L196.718 147.98L194.912 151.546C194.687 151.916 194.542 152.328 194.486 152.756C194.43 153.185 194.464 153.62 194.587 154.034C194.71 154.449 194.918 154.834 195.198 155.165C195.479 155.497 195.826 155.767 196.218 155.959C196.609 156.152 197.037 156.262 197.474 156.284C197.911 156.305 198.348 156.237 198.757 156.084C199.166 155.931 199.538 155.696 199.851 155.394C200.163 155.092 200.409 154.729 200.573 154.329Z"
        fill="#F7F7FC"
      />
      <path
        d="M185.507 162.808L179.406 158.209L181.355 155.687L196.399 167.08L191.994 172.808C189.615 175.874 186.487 176.678 183.569 174.461C181.047 172.558 180.727 170.015 182.17 167.395L172.644 167.014L174.846 164.166L184.152 164.634L185.507 162.808ZM187.644 164.416L185.188 167.591C183.888 169.254 184.086 170.852 185.463 171.939C186.84 173.026 188.492 172.732 189.791 171.058L192.247 167.895L187.644 164.416Z"
        fill="#F7F7FC"
      />
      <path
        d="M173.811 191.125L171.917 189.038L178.8 182.972L175.496 179.287L169.263 184.788L167.401 182.722L173.635 177.222L170.011 173.232L163.139 179.287L161.223 177.178L170.452 169.047L183.062 182.994L173.811 191.125Z"
        fill="#F7F7FC"
      />
      <path
        d="M159.263 184.701C158.558 184.095 157.665 183.746 156.731 183.71C155.798 183.675 154.88 183.955 154.13 184.505C152.236 185.646 151.542 187.266 152.456 188.755C153.139 189.842 154.196 190.125 156.124 189.505L159.263 188.516C161.829 187.69 164.351 187.82 165.937 190.386C167.522 192.951 166.608 196.462 163.337 198.43C159.835 200.528 156.73 199.984 154.527 197.68L157.269 196.039C157.922 196.575 158.739 196.879 159.588 196.9C160.438 196.922 161.27 196.661 161.95 196.158C163.69 195.071 164.329 193.517 163.514 192.19C162.864 191.103 161.763 190.853 160.1 191.386L156.884 192.473C153.998 193.419 151.443 192.951 149.967 190.56C148.128 187.57 149.417 184.266 152.765 182.266C156.113 180.265 159.703 180.461 162.104 183.07L159.263 184.701Z"
        fill="#F7F7FC"
      />
      <path
        d="M144.075 192.843C143.492 192.124 142.676 191.624 141.764 191.426C140.853 191.228 139.9 191.344 139.064 191.756C136.994 192.527 136.003 194.006 136.63 195.625C137.104 196.854 138.095 197.289 140.111 197.028L143.415 196.615C146.08 196.267 148.547 196.832 149.626 199.648C150.705 202.463 149.174 205.746 145.606 207.094C141.774 208.529 138.778 207.442 137.027 204.778L140.022 203.691C140.566 204.336 141.315 204.78 142.146 204.952C142.978 205.124 143.844 205.013 144.604 204.637C146.509 203.93 147.434 202.463 146.873 201.017C146.421 199.854 145.397 199.397 143.668 199.626L140.309 200.071C137.302 200.485 134.868 199.571 133.866 196.952C132.6 193.69 134.472 190.668 138.139 189.342C141.807 188.016 145.287 188.81 147.17 191.821L144.075 192.843Z"
        fill="#F7F7FC"
      />
      <path
        d="M123.877 212.822L108.161 214.192L107.908 211.398L114.174 210.855L112.765 195.028L115.959 194.756L117.368 210.561L123.635 210.018L123.877 212.822Z"
        fill="#F7F7FC"
      />
      <path
        d="M96.7184 194.375C101.806 194.941 104.934 199.398 104.306 204.931C103.679 210.464 99.6259 214.127 94.5378 213.562C89.4497 212.996 86.2889 208.572 86.9276 203.007C87.5664 197.441 91.5862 193.821 96.7184 194.375ZM94.8572 210.746C98.1611 211.116 100.639 208.507 101.091 204.572C101.542 200.637 99.714 197.56 96.399 197.191C93.084 196.821 90.595 199.43 90.1435 203.365C89.692 207.3 91.5091 210.377 94.8131 210.746H94.8572Z"
        fill="#F7F7FC"
      />
      <path
        d="M71.6303 195.745L74.4166 189.342L77.3572 190.582L69.8902 207.757L63.1721 204.92C59.5818 203.387 58.0289 200.571 59.5708 196.995C61.1126 193.419 64.2624 192.647 67.8638 194.147L71.6303 195.745ZM64.3285 202.419L68.084 204.039L70.573 198.31L66.8616 196.734C66.477 196.551 66.0588 196.446 65.6322 196.427C65.2056 196.407 64.7793 196.473 64.3793 196.621C63.9792 196.768 63.6136 196.994 63.3046 197.285C62.9955 197.576 62.7494 197.926 62.581 198.313C62.4127 198.701 62.3257 199.118 62.3252 199.54C62.3247 199.961 62.4108 200.378 62.5782 200.766C62.7456 201.154 62.991 201.504 63.2994 201.796C63.6078 202.088 63.9728 202.314 64.3726 202.463L64.3285 202.419Z"
        fill="#F7F7FC"
      />
      <path
        d="M45.0553 194.93L49.5597 175.363L52.269 177.156L50.9694 182.527L56.9826 186.505L61.5201 183.244L64.2073 185.027L47.6214 196.549L45.0553 194.93ZM54.5928 188.266L50.2866 185.418L48.5465 192.582L54.5928 188.266Z"
        fill="#F7F7FC"
      />
      <path
        d="M42.6434 179.211L34.5046 187.06L32.3019 184.799L40.1984 177.189C42.7976 174.678 42.9407 172.417 40.9583 170.417C38.976 168.417 36.6852 168.493 34.0861 171.004L26.1786 178.613L23.976 176.341L32.1368 168.482C35.8482 164.906 39.846 165.221 43.0398 168.482C46.2337 171.743 46.3769 175.624 42.6434 179.211Z"
        fill="#F7F7FC"
      />
      <path
        d="M29.6258 161.318C30.2112 160.598 30.5303 159.703 30.5303 158.78C30.5303 157.857 30.2112 156.962 29.6258 156.242C28.4254 154.405 26.7514 153.763 25.2866 154.709C24.1853 155.416 23.943 156.47 24.6258 158.362L25.7271 161.427C26.6412 163.927 26.5862 166.427 24.0201 168.069C21.454 169.71 17.8857 168.906 15.8152 165.732C13.6126 162.34 14.0311 159.209 16.3108 156.97L18.0509 159.633C17.5248 160.291 17.2386 161.105 17.2386 161.943C17.2386 162.782 17.5248 163.595 18.0509 164.253C19.1522 165.938 20.7932 166.525 22.1148 165.688C23.2161 165.003 23.4033 163.916 22.8196 162.297L21.7183 159.144C20.6831 156.329 21.0795 153.796 23.4584 152.274C26.432 150.372 29.813 151.546 31.9386 154.785C34.0641 158.025 33.965 161.579 31.3879 164.025L29.6258 161.318Z"
        fill="#F7F7FC"
      />
      <path
        d="M4.20728 142.078L6.82842 140.99L10.3637 149.404L14.9782 147.513L11.7844 139.903L14.3725 138.816L17.5773 146.426L22.5884 144.371L19.0531 135.957L21.7073 134.87L26.443 146.143L8.94296 153.307L4.20728 142.078Z"
        fill="#F7F7FC"
      />
      <path
        d="M4.52672 71.9848L18.8439 73.0066L6.72936 65.3973L7.70954 62.5057L24.1192 72.9957L23.1941 75.7677L9.22936 74.8763L20.9144 82.4856L19.9783 85.225L0.484863 83.8553L1.47605 80.9203L15.7932 82.0726L3.6787 74.3328L4.52672 71.9848Z"
        fill="#F7F7FC"
      />
      <path
        d="M15.4517 49.6786L35.3857 53.3963L33.6676 56.2009L28.161 55.1138L24.3504 61.2013L27.7866 65.5495L26.0905 68.2779L13.8218 52.3092L15.4517 49.6786ZM22.5443 58.8424L25.2755 54.4942L17.9517 53.0484L22.5443 58.8424Z"
        fill="#F7F7FC"
      />
      <path
        d="M21.476 40.8735L32.1368 29.3834L34.2293 31.2749L29.9782 35.8513L41.8614 46.5805L39.6588 48.9177L27.8196 38.1885L23.5685 42.765L21.476 40.8735Z"
        fill="#F7F7FC"
      />
      <path
        d="M42.5002 20.8501C43.9054 19.6784 45.707 19.0733 47.5446 19.156C49.3823 19.2386 51.1206 20.0028 52.4121 21.2958L49.8791 23.1438C49.0769 22.5022 48.0757 22.1521 47.0432 22.1521C46.0106 22.1521 45.0095 22.5022 44.2073 23.1438C41.531 25.1005 41.6192 28.579 43.976 31.7532C46.3328 34.9274 49.6808 36.0362 52.3791 34.0686C53.2398 33.499 53.8752 32.6538 54.1782 31.6756C54.4811 30.6973 54.433 29.6459 54.0421 28.6986L56.5751 26.8506C57.4588 28.4491 57.6968 30.3184 57.2412 32.0829C56.7856 33.8475 55.6702 35.3764 54.1192 36.3623C50.1985 39.2212 44.846 38.2755 41.3879 33.6556C37.9297 29.0356 38.5795 23.7091 42.5002 20.8501Z"
        fill="#F7F7FC"
      />
      <path
        d="M69.8241 6.47937L77.5333 23.546L74.6038 24.8396L71.2117 17.3607L62.5774 21.198L65.8813 28.6769L62.9518 29.9704L55.2426 12.9038L58.1721 11.632L61.3549 18.6652L70.0554 14.8279L66.8725 7.7947L69.8241 6.47937Z"
        fill="#F7F7FC"
      />
      <path
        d="M92.6435 20.1653L89.4827 20.7197L86.1787 2.32685L89.3285 1.77246L92.6435 20.1653Z"
        fill="#F7F7FC"
      />
      <path
        d="M112.478 18.9587H110.011L100.1 5.53365L100.331 19.2413H97.2471L96.8837 0.554976L99.9013 0.500624L109.285 13.2952L109.031 0.326697L112.115 0.261475L112.478 18.9587Z"
        fill="#F7F7FC"
      />
      <path
        d="M140.771 5.85978L139.956 19.9914L147.379 7.94691L150.309 8.8709L139.934 25.1766L137.115 24.2961L137.809 10.4906L130.254 22.1764L127.456 21.3067L128.558 2.04425L131.553 2.97911L130.595 17.1107L138.304 5.07711L140.771 5.85978Z"
        fill="#F7F7FC"
      />
      <path
        d="M153.161 32.3619C148.756 29.7204 147.875 24.383 150.826 19.6326C153.778 14.8822 159.02 13.2734 163.381 15.9149C167.743 18.5564 168.701 23.883 165.727 28.6551C162.754 33.4272 157.522 35.0034 153.161 32.3619ZM161.884 18.3282C159.042 16.6106 155.683 17.9368 153.591 21.3067C151.498 24.6765 151.817 28.2311 154.692 30.003C157.566 31.7749 160.914 30.4161 163.007 27.0463C165.099 23.6764 164.714 20.0457 161.884 18.3282Z"
        fill="#F7F7FC"
      />
      <path
        d="M174.263 49.222L172.489 47.5045L175.155 31.0901L165.551 40.8735L163.349 38.6994L176.564 25.2961L178.767 27.3833L176.157 42.9498L185.309 33.6555L187.512 35.8296L174.263 49.222Z"
        fill="#F7F7FC"
      />
      <path
        d="M192.831 42.2758L196.344 47.5045C199.428 52.1027 198.326 57.0923 193.514 60.223C188.701 63.3537 183.602 62.3971 180.551 57.8097L177.027 52.5484L192.831 42.2758ZM182.919 56.1791C185.122 59.4403 188.426 59.8208 191.796 57.6141C195.166 55.4073 196.201 52.2549 193.998 48.9829L192.225 46.4175L181.212 53.6246L182.919 56.1791Z"
        fill="#F7F7FC"
      />
      <path
        d="M209.23 72.7349L206.597 73.8219L203.106 65.3973L198.481 67.2671L201.652 74.8764L199.053 75.9634L195.881 68.3541L190.859 70.3325L194.351 78.7571L191.696 79.8442L187.027 68.528L204.56 61.4405L209.23 72.7349Z"
        fill="#F7F7FC"
      />
      <path
        d="M200.959 84.9967L193.436 86.5077L192.798 83.377L211.344 79.6484L212.809 86.7142C213.591 90.4972 212.159 93.3343 208.536 94.0627C205.419 94.6932 203.271 93.2583 202.214 90.4537L195.672 97.3021L194.945 93.78L201.41 87.1708L200.959 84.9967ZM203.547 84.4641L204.351 88.3774C204.769 90.4319 206.135 91.3668 207.853 91.0189C209.571 90.6711 210.452 89.2905 210.055 87.236L209.24 83.3226L203.547 84.4641Z"
        fill="#F7F7FC"
      />
      <path
        d="M199.725 101.313C199.757 101.728 199.663 102.143 199.456 102.505C199.248 102.867 198.937 103.16 198.56 103.346C198.184 103.533 197.759 103.605 197.341 103.554C196.923 103.502 196.53 103.329 196.212 103.056C195.894 102.783 195.665 102.424 195.554 102.022C195.444 101.621 195.457 101.196 195.591 100.802C195.726 100.408 195.976 100.062 196.31 99.8087C196.644 99.5552 197.047 99.4054 197.467 99.3784C197.745 99.3562 198.024 99.3889 198.289 99.4746C198.553 99.5603 198.798 99.6973 199.008 99.8775C199.218 100.058 199.39 100.278 199.513 100.524C199.636 100.771 199.708 101.039 199.725 101.313V101.313ZM214.461 98.5957L214.703 102.248L201.696 102.303L201.564 100.128L214.461 98.5957Z"
        fill="#F7F7FC"
      />
    </svg>
  )
}

const EventsButton = styled(motion.button)`
  cursor: pointer;
  width: 275px;
  border-radius: 50px;
  border: 2px solid var(--color-black);
  background-color: var(--color-white);
  color: var(--color-black);
  font-family: "calibre-medium";
  font-size: 20px;
  line-height: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem 0.75rem;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: var(--color-black);
    color: var(--color-white);
    svg {
      fill: var(--color-white);
    }
  }
  svg {
    scale: 0.8;
    margin-left: 0.25rem;
    fill: var(--color-black);
  }

  & :disabled {
    opacity: 0.5;
    cursor: default;
    :hover {
      background-color: var(--color-white);
      color: var(--color-black);
      svg {
        fill: var(--color-black);
      }
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    border: 1px solid var(--color-black);
    font-size: 16px;
    padding: 0.35rem 0.75rem;
    width: 230px;
    svg {
      scale: 0.75;
    }
  }
`

const EmblaButton = styled(motion.button)`
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 45%;
  /* transform: translateY(-50%); */
  border: 0;
  width: 51px;
  height: 58px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  @media (max-width: ${breakpoints.s}px) {
    top: 91%;

    svg {
      max-width: 38px;
      opacity: 0.9;
      filter: brightness(6);
    }
  }
`
const EmblaButtonPress = styled(motion.button)`
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 45%;
  /* transform: translateY(-50%); */
  border: 0;
  width: 51px;
  height: 58px;
  justify-content: center;
  align-items: center;
  fill: var(--color-purple);
  padding: 0;

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  @media (max-width: ${breakpoints.s}px) {
    top: 91%;

    svg {
      max-width: 38px;
      opacity: 0.9;
    }
  }
`
